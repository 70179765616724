import { request } from '../utils/https/request'
import { minirequest } from '../utils/https/minirequest'
import { toRaw } from '@vue/reactivity'

export interface WechatXcxUserInfodata {
    code?: string
    signature: string
    rawData: string
    encrypted_data: string
    iv: string
    session_key?: string
}
export interface WechatXcxUserInfoResult {
    // 头像
    avatarUrl: string
    city: string
    country: string
    gender: number
    language: string
    nickName: string
    openId: string
    province: string
    unionId: string
    watermark: {
        appid: string
        timestamep: string
    }
}
/** 小程序获取用户信息 */
export const WechatXcxUserInfo = (data: WechatXcxUserInfodata) => {
    return request({ url: '60054ebbb8e01', method: 'POST', data })
}

export interface WechatXcxLogindata {
    openid: string
    unionid: string
    nickname: string
    head_url: string
    longitude?: string | number
    latitude?: string | number
    user_id?: string
    media_id?: string
    version: string
}
export interface UserInfo {
    id: number
    farmer_name: string
    address: string
    longitude: string
    latitude: string
    openid: string
    unionid: string
    head_url: string
    last_login: string
    last_login_version: string
}
export interface WechatXcxLoginResult {
    user_info: UserInfo
}
/** 小程序登录 */
export const WechatXcxLogin = (data: WechatXcxLogindata) => {
    return request({ url: '60055f1131cdc', method: 'POST', data })
}

export interface WechatMaterialdata {
    farmer_id?: string
    phase?: number
    flag?: number
    species_id?: number
}
export interface HomeTemplate {
    displayorder: number
    err_tips: string
    favorite_num: number
    form_id: number
    has_other: number
    id: number
    is_favorite: string
    item: string
    item_audio: string
    item_image: string
    item_type: string
    item_video: null
}
export interface TopicTree {
    displayorder: number
    err_tips: string
    form_id: number
    has_other: number
    id: number
    is_right: number
    is_right_text: string
    item: string
    item_image: string
    item_type: string
    childs: {
        displayorder: number
        err_tips: string
        form_id: number
        form_template_id: number
        id: number
        is_right: number
        item_option: string
        remark: string
        selected: boolean
        template_sort: number
    }[]
}
export interface SignInfo {
    address: string
    city: string
    farmer_id: number
    farmer_name: string
    group_buy_name: string
    id: number
    product_id: number
    province: string
    question: string
    sale_num: number
    tel: string
}
export interface WechatMaterialResult {
    active_phase: number
    chosen_phase: number
    home_info: {
        company_id: number
    }
    home_setting: {
        title_video: string
        title_audio: string
    }[]
    home_template: HomeTemplate[]
    sign_info: SignInfo
    topic_detail: any[]
    topic_info: any
    topic_setting: any[]
    topic_template: any[]
    topic_tree: TopicTree[]
}
/** 获取素材 */
export const WechatMaterial = (data: WechatMaterialdata) => {
    return request({ url: '600033692048e', method: 'POST', data })
}

export interface MaterialLovedata {
    template_id: number
    farmer_id: number
}
export interface MaterialLoveResult {
    any: any
}
/** 给图片点赞 */
export const MaterialLove = (data: MaterialLovedata) => {
    return request({ url: '6006740f7cad5', method: 'POST', data })
}

export interface SaveFormDatadata {
    farmer_id: number
    openid: string
    unionid: string
    form_id: number
    data_details?: string
    is_valid?: number
    success_count: number
    total_count: number
}
export interface SaveFormDataResult {
    msg: string | number | undefined
}
/** 保存题目答案 */
export const saveFormData = (data: SaveFormDatadata) => {
    return request({ url: '6006733a62ebc', method: 'POST', data })
}

export interface GetBadgeRecodedata {
    farmer_id: string
    phase: number
    species_id: number
}
export interface GetBadgeRecodeResult {
    [index: number]: {
        farmer_id: number
        giftnum: number
        rowno: number
        msg: string
        show_str: string
        is_jump: number
        tip_image: string
    }
}
/** 获取的奖牌数量 */
export const getBadgeRecord = (data: GetBadgeRecodedata) => {
    return request({ url: '60067ebee685a', method: 'POST', data })
}

export interface Getprovincedata {
    any: any
}
export interface AddressItem {
    create_time: string
    deep: number
    ext_name: string
    id: number
    name: string
    operator: string
    pid: number
    pinyin: string
    pinyin_shor: string
    update_time: string
    children?: AddressItem[]
}
export type GetprovinceResult = AddressItem[]
/** 获取省市区 */
export const getProvince = (data: Getprovincedata) => {
    return request({ url: '6006850d5492a', method: 'POST', data })
}

export interface GetPhoneNumberdata {
    code?: string
    encrypted_data: string
    iv: string
    sesstion_key?: string
}
export interface GetPhoneNumberResult {
    any: any
}
/** 获取手机号 */
export const getPhoneNumber = (data: GetPhoneNumberdata) => {
    return request({ url: '600552c3aad98', method: 'POST', data })
}

export interface GetSideUpdata {
    farmer_id: number
    farmer_name: string
    tel: string
    province: string
    city: string
    address: string
    question?: string
}
export interface GetSideUpResult {
    any: any
}
/** 报名 */
export const getSignUp = (data: GetSideUpdata) => {
    return request({ url: '60067f9b01664', method: 'POST', data })
}

/** 获取海豚位置经纬度信息 */
export const getAddressInfo = (data: any) => {
    return request({ url: '61b9b6b8281b1', method: 'POST', data })
}

/** 获取物种列表 */
export const getSpeciesList = (data: { data_type: string }) => {
    return request({ url: '621756213546c', method: 'POST', data })
}

/** 获取期数列表 */
export const getPeriodList = (data: any) => {
    return request({ url: '621759486d688', method: 'POST', data })
}

/** 获取页面图片列表 */
export const getPageImgList = (data: any) => {
    return request({ url: '621764e77e7fc', method: 'POST', data })
}

/** 根据页面键值获取页面内容 */
export const getDescriptionContent = <T>(data: {
    data_key: 'about_us' | 'ous_partners' | 'contact_us';
}) => {
    return request<T>({
        url: '62183952dc3e9',
        method: 'POST',
        data,
    })
}

/** 获取种群数据，用来切换地图展示的点 */
export const getPopulationList = (data: any) => {
    return request({ url: '62184538a36a0', method: 'POST', data })
}

/** 获取地图显示数据*/
export const getMapData = <T>(data: { data_type: string; population?: string }) => {
    return request<T>({ url: '62184d471e87c', method: 'POST', data })
}

/** 添加观豚数据 */
export const addWatchDolphinData = (data: any) => {
    return request({ url: '621c69a110bfb', method: 'POST', data })
}

/** 添加搁浅数据 */
export const addStrandedRescue = (data: any) => {
    return request({ url: '621d87ded2f89', method: 'POST', data })
}

/** 获取统计数据 */
export const getStatisticsData = <T>(data: any) => {
    return request<T>({ url: '621d88b025781', method: 'POST', data })
}

/** 获取图表显示数据 */
export const getEchartData = (data: any) => {
    return request({ url: '621d884656add', method: 'POST', data })
}

/** 获取个体详细信息 */
export const individualDetailInfo = (data: any) => {
    return request({ url: '6221bcab6f2e8', method: 'POST', data })
}

/** 更新用户信息 */
export const updateUserInfo = (data: any) => {
    return request({ url: '6221e4e62eaa2', method: 'POST', data })
}

/** 获取浏览数据列表 */
export const getBrowseData = (data: any) => {
    return request({ url: '6230433a89b83', method: 'POST', data })
}

/** 获取浏览数据列表 */
export const addImageIdentify = (data: any) => {
    return request({ url: '621d87fd57a4e', method: 'POST', data })
}

/** 获取应用配置 */
export const appConfig = (data: any) => {
    return request({ url: '623149894b030', method: 'POST', data })
}

/** 物种识别 */
export const speciesIdentify = (data: { image_url: string }) => {
    return request({ url: '6234b44968e0d', method: 'POST', data })
}

/** 获取分享海报素材 */
export const posterMaterial = (data: {
    species_id: number
    data_type: string
    template_id?: number // 影像博物馆图片id
}) => {
    return request({ url: '6243e15202500', method: 'POST', data })
}

/** 获取系统信息、答题记录 */
export const getLogList = (data: { data_type?: string; user_id: number }) => {
    return request({ url: '624527c948ab3', method: 'POST', data })
}

/** 获取菜单数据统计提示 */
export const statisticsTip = (data: any) => {
    return request({ url: '6243e21e45041', method: 'POST', data })
}

/** 获取个体列表 */
export const getIndividualList = (data: any) => {
    return request({ url: '62456d24af272', method: 'POST', data })
}

/** 【iDolphin小程序】保存答题记录 */
export const answerRecord = (data: {
    /** 用户ID */
    farmer_id: number
    /** 问卷ID */
    form_id: number
    /** 题目ID */
    form_template_id: number
    /** 答案ID */
    form_detail_id: number
    /** 是否答对 ，1=对，0= 错 */
    is_right: number
}) => {
    return request({ url: '62551fe010570', method: 'POST', data })
}

/** 【iDolphin小程序】去除用户信息提醒 */
export const clearUserTip = (data: {
    user_id: number // 用户ID
    data_type: string // 数据类型，SYS=系统信息，DIM=答题，LEK=观豚，MSN=搁浅，PID=识别
}) => {
    return request({ url: '62553b984795b', method: 'POST', data })
}

/** 获取中奖名单 */
export const getWinnerList = (data: any) => {
    return request({ url: '62610d10385d9', method: 'POST', data })
}

//获取主页的背景视频&图片
export const getHomePageBackground = (data: any) => {
    return request({ url: '62b3de173ba51', method: 'POST', data })
}
export const getAdImgAndVideo = (id: number) => {
    try {
        const src = getHomePageBackground({
            cid: 9,
            pid: id
        })
        return src
    } catch (e) { console.debug(e) }
}

//获取顶部栏的item项
export const getTopBarItems = (data: any) => {
    return request({ url: '62b3de55dd9bd', method: 'POST', data })
}
//根据顶部栏id获取页面内容
export const getMessageByTopBarItemID = (data: any) => {
    return request({ url: '62b42757da89c', method: 'POST', data })
}
export const getPageMessage = async (id: string, refine?: Array<Array<number>> | any, keyword?: string | any) => {
    try {
        const params: any = {
            cid: 9,
            aid: id,
        }
        if (refine?.length > 0)
            params['refine'] = refine
        if (keyword?.length > 0)
            params['keyword'] = keyword
        const res = await getMessageByTopBarItemID(params)
        return res;
    } catch (e) { console.error(e); }
}
//获取友情链接
export const getLinks = (data: any) => {
    return request({ url: '62b7cbb2db5e8', method: 'POST', data })
}
//影像博物馆图片列表
export const getMiniPic = (data: any) => {
    return minirequest({ url: '621756213546c', method: 'POST', data })
}
//影像博物馆图片列表内容
export const getMiniPicDetail = (data: any) => {
    return minirequest({ url: '621759486d688', method: 'POST', data })
}
//获取网页内容
export const getMiniPicDetailPath = (data: any) => {
    return minirequest({ url: '600033692048e', method: 'POST', data })
}
//获取图表数据
export const getMiniEchartData = (data: any) => {
    return minirequest({ url: '621d884656add', method: 'POST', data })
}
/**
 * 【CMS】根据内容ID获取关联内容列表 - 
 * @param data 
 * @returns 
*/
export const getRelatedContentById = (data: any) => {
    return minirequest({ url: '64b4fdbf051a3', method: 'POST', data })
}


/**【CMS】用户注册  */
export const userRegister = (data: {
    name: string,
    tel: string,
    passward: string
}) => {
    return request({ url: '657296457b238', method: 'POST', data })
}
/**【CMS】用户注册  */
export const userLogin = (data: {
    userName: string,
    password: string
}) => {
    return request({ url: '657296f980011', method: 'POST', data })
}

/**【CMS】获取栏目内容列表 -   */
export const getActionsList = (data: {
    arctypeId: 229 | 230,
    customerId: string
}) => {
    return request({ url: '65729772754e8', method: 'POST', data })
}

/**【CMS】获取Studies列表 -  -   */
export const getStudiesList = (data: {
    archivesId: number
}) => {
    return request({ url: '6572eb8ec11d5', method: 'POST', data })
}

/**【CMS】添加研究或行动内容 -  -  -   */
export const addActionOrStudies = (data: {
    arctypeId: 229 | 230,
    customerId: string,
    title: string,
    content: string,
    articleRelationId: string,
}) => {
    return request({ url: '6572caee1f1a3', method: 'POST', data })
}
/**【CMS】更新研究或行动内容 -  -  -  -   */
export const updateActionOrStudies = (data: {

    /**内容ID */
    archivesId: number,
    customerId: string,
    title: string,
    content: string,
    articleRelationId: string,
}) => {
    return request({ url: '6572ec178b7e0', method: 'POST', data })
}
/**【CMS】用户删除内容 -  -  -  -  -   */
export const deleteRow = (data: {
    /**内容ID */
    archivesId: number,
    customerId: string,
}) => {
    return request({ url: '6576af99aac0d', method: 'POST', data })
}
/**【CMS】获取用户注册验证码 -  -  -  -  -  -  -   */
export const registerCaptcha = (data: {
    /**手机号 */
    mobile: number | string,
}) => {
    return request({ url: '6576c06fcfa4d', method: 'POST', data })
}
/**【CMS】获取修改密码验证码 -  -  -  -  -  -   */
export const changePasswordCaptcha = (data: {
    /**手机号 */
    mobile: number,
}) => {
    return request({ url: '65768b37276f5', method: 'POST', data })
}
/**【CMS】修改密码 -  -  -  -  -  -  -   */
export const changePassword = (data: {
    /**手机号 */
    userName: number | string,
    /**新密码 */
    password: number | string,
    /**旧密码 */
    oldPassword?: number | string,
    /**短信验证码，输入时使用验证码验证，不验证旧密码 */
    captcha: number | string,
}) => {
    return request({ url: '6576c2ac75ad3', method: 'POST', data })
}
/**【CMS】获取内容细化分类关联 -  */
export const ContentMiniSortList = (data: {
    /**内容ID */
    archivesId: number | string,
}) => {
    return request({ url: '65b086f81071a', method: 'POST', data })
}
/**【CMS】获取Actions列表 -   */
export const ActionsList = (data: {
    /**内容ID */
    archivesId: number | string,
}) => {
    return request({ url: '65b07bda3bec4', method: 'POST', data })
}
/**【CMS】添加研究或行动内容 - */
export const addContent = (data: {
    /**栏目ID，研究=229，行动=230 */
    arctypeId: number
    /**用户ID */
    customerId: number
    /**标题 */
    title: string
    /**内容 */
    content: string
    /**作者，长度200 */
    author: string
    /**出版年份，格式：2024-01-05 */
    publish_time: string
    /**DOI，长度255 */
    doi: string
    /**参考文献，长度255 */
    reference: string
    /**关联行动内容ID，栏目为研究时传入，可多选，用逗号分隔 */
    articleRelationId: number[]
    /**关联细化分类ID，可多选，用逗号分隔 */
    refineRelationId: number[]
}) => {
    return request({ url: '6572caee1f1a3', method: 'POST', data })
}
/**【CMS】更新研究或行动内容 -  - */
export const edItContent = (data: {
    /**内容ID */
    archivesId: number
    /**用户ID */
    customerId: number
    /**标题 */
    title: string
    /**内容 */
    content: string
    /**作者，长度200 */
    author: string
    /**出版年份，格式：2024-01-05 */
    publish_time: string
    /**DOI，长度255 */
    doi: string
    /**参考文献，长度255 */
    reference: string
    /**关联行动内容ID，栏目为研究时传入，可多选，用逗号分隔 */
    articleRelationId: number[]
    /**关联细化分类ID，可多选，用逗号分隔 */
    refineRelationId: number[]
}) => {
    return request({ url: '6572ec178b7e0', method: 'POST', data })
}