import axios from "axios";
import type { AxiosRequestConfig } from 'axios';

import { setupInterceptor } from '@/utils/https/interceptors/index';

interface IResponse<T> {
  data: T;
  code: number;
  msg: string;
}

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5000,
  headers: {
    'access-token': 'yJHBlgSKbqydpjkvRPdvnNftBkQovHKm',
    'user-token': 'huanghongjian;0d5f5be69506c35fc17983e64ab082e6',
  },
});

export async function minirequest<T = any>(config: AxiosRequestConfig): Promise<IResponse<T>> {
  config.data.app_code = process.env.VUE_APP_APP_CODE;
  config.data.qequest_app = 'web'
  setupInterceptor(instance);

  return (await instance(config)).data;
}

